@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap);
#near-wallet-selector-modal {
  --backdrop-bg: #26262630;
  --heading-color: #262626;
  --text-color: #676767;
  --selected-wallet-bg: #A7A7A730;
  --selected-wallet-bg-hover: transparent;
  --wallet-option-border-color: #A7A7A730;
  --content-bg: #FFFFFF;
  --input-border-color-focus: #5F8AFA;
  --box-shadow-color: #26262630;
  --dismiss-button-bg-hover: #A7A7A730;
  --dismiss-button-border-color-hover: inherit;
  --confirm-button-color: #FFFFFF;
  --confirm-button-bg: #5F8AFA;
  --confirm-button-bg-hover: #5AA6FF;
  --confirm-button-border-color: #5F8AFA;
  --error: #DB5555;
  --close-button-color: #262626;
  --spinner-color: #676767;
}

#near-wallet-selector-modal .dark-theme {
  --backdrop-bg: #26262630;
  --heading-color: #FFFFFF;
  --text-color: #FFFFFF;
  --selected-wallet-bg: #262626CC;
  --selected-wallet-bg-hover: #262626CC;
  --wallet-option-border-color: #A7A7A730;
  --content-bg: #3F4246;
  --input-border-color-focus: #5F8AFA;
  --box-shadow-color: #26262630;
  --dismiss-button-bg-hover: #262626CC;
  --dismiss-button-border-color-hover: #262626;;
  --confirm-button-color: #FFFFFF;
  --confirm-button-bg: #5F8AFA;
  --confirm-button-bg-hover: #5AA6FF;
  --confirm-button-border-color: #5F8AFA;
  --error: #DB5555;
  --close-button-color: #A7A7A7;
  --spinner-color: #FFFFFF;
}

@media (prefers-color-scheme: dark) {
  #near-wallet-selector-modal {
    --backdrop-bg: #26262630;
    --heading-color: #FFFFFF;
    --text-color: #FFFFFF;
    --selected-wallet-bg: #262626CC;
    --selected-wallet-bg-hover: #262626CC;
    --wallet-option-border-color: #A7A7A730;
    --content-bg: #3F4246;
    --input-border-color-focus: #5F8AFA;
    --box-shadow-color: #26262630;
    --dismiss-button-bg-hover: #262626CC;
    --dismiss-button-border-color-hover: #262626;;
    --confirm-button-color: #FFFFFF;
    --confirm-button-bg: #5F8AFA;
    --confirm-button-bg-hover: #5AA6FF;
    --confirm-button-border-color: #5F8AFA;
    --error: #DB5555;
    --close-button-color: #A7A7A7;
    --spinner-color: #FFFFFF;
  }
}
/**
 * Modal Wrapper
 */

.nws-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  /*transition: visibility 0s linear 0.25s, opacity 0.25s 0s;*/
  color: var(--text-color);
  color: var(--wallet-selector-text-color, var(--text-color));
  font-family: Manrope, sans-serif;
  font-weight: 500;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nws-modal-wrapper .modal-overlay {
  background: var(--backdrop-bg);
  background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
  height: 100%;
  width: 100%;
  position: absolute;
}
/**
 * Modal
 */

.nws-modal-wrapper .modal {
  background: var(--content-bg);
  background: var(--wallet-selector-content-bg, var(--content-bg));
  width: 400px;
  max-width: 700px;
  height: auto;
  max-height: 70vh;
  border-radius: 16px;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
  background-color: var(--content-bg);
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  padding: 32px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 1.6;
}

.nws-modal-wrapper .modal {
  box-sizing: content-box;
}

/**
 * Modal Header
 */

.nws-modal-wrapper .modal .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.nws-modal-wrapper .modal .modal-header .close-button {
  border: 0;
  cursor: pointer;
  height: 24px;
  padding: 4px;
  background-color: transparent;
}

.nws-modal-wrapper .modal .modal-header .close-button:active {
  background: transparent;
}

.nws-modal-wrapper .modal .modal-header .close-button svg {
  pointer-events: none;
}

.nws-modal-wrapper .modal .modal-header .close-button:hover svg {
  fill: var(--close-button-color);
  fill: var(--wallet-selector-close-button-color, var(--close-button-color));
  transition: all 0.2s ease-in;
}

.nws-modal-wrapper .modal .modal-header h2 {
  color: var(--heading-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 20px;
}

/**
 * Modal buttons and inputs
 */

.nws-modal-wrapper .modal .modal-body input,
.nws-modal-wrapper .modal .modal-body button {
  background: inherit;
  font-size: 14.2px;
  font-family: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 40px;
  margin-top: 8px;
  padding: 0.55em 1.4em;
  text-align: center;
  color: inherit;
  transition: background 150ms ease-in-out;
  line-height: 1.15;
  cursor: pointer;
}

.nws-modal-wrapper .modal .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nws-modal-wrapper .modal .action-buttons .left-button:hover {
  background-color: var(--dismiss-button-bg-hover);
  background-color: var(--wallet-selector-dismiss-button-bg-hover, var(--dismiss-button-bg-hover));
  border-color: var(--dismiss-button-border-color-hover);
  border-color: var(--wallet-selector-dismiss-button-border-color-hover, var(--dismiss-button-border-color-hover))
}

.nws-modal-wrapper .modal .action-buttons .right-button {
  color: var(--confirm-button-color);
  color: var(--wallet-selector-confirm-button-color, var(--confirm-button-color));
  background-color: var(--confirm-button-bg);
  background-color: var(--wallet-selector-confirm-button-bg, var(--confirm-button-bg));
  border: 1px solid var(--confirm-button-border-color);
  border: 1px solid var(--wallet-selector-confirm-button-border-color, var(--confirm-button-border-color));
}

.nws-modal-wrapper .modal .action-buttons .right-button:hover {
  background-color: var(--confirm-button-bg-hover);
  background-color: var(--wallet-selector-confirm-button-bg-hover, var(--confirm-button-bg-hover));
}

/**
 * Modal Switch Network Message Section/Wrapper
 */

.nws-modal-wrapper .modal .switch-network-message-wrapper .header h2 {
  font-size: 18px;
  margin-top: 0;
  color: var(--heading-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
}

.nws-modal-wrapper .modal .switch-network-message-wrapper .content  p {
  font-size: 14.25px;
}

/**
 * Modal Ledger Derivation Path Section/Wrapper
 */

.nws-modal-wrapper .modal .derivation-path-wrapper .derivation-path-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}

.nws-modal-wrapper .modal .derivation-path-wrapper input {
  margin-right: 8px;
}

.nws-modal-wrapper .modal .derivation-path-wrapper input:focus {
  border: 2px solid var(--input-border-color-focus);
  border: 2px solid var(--wallet-selector-input-border-color-focus, var(--input-border-color-focus));
}

.nws-modal-wrapper .modal .derivation-path-wrapper input:focus-visible {
  border: none;
  outline: 2px solid var(--input-border-color-focus);
  outline: 2px solid var(--wallet-selector-input-border-color-focus, var(--input-border-color-focus));
}

.nws-modal-wrapper .modal .derivation-path-wrapper .input-error {
  border-color: var(--error) !important;
  border-color: var(--wallet-selector-error, var(--error)) !important;
}

.nws-modal-wrapper .modal .derivation-path-wrapper .error {
  font-family: inherit;
  color: var(--error);
  color: var(--wallet-selector-error, var(--error));
}

/**
 * Modal Wallet ChooseLedgerAccountForm/Wrapper
 */
.nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control {
  display: flex;
  margin-bottom: 16px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}
.nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control label {
  color: inherit;
}
.nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control select {
  padding: 8px;
  font-size: 14px;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: right;
  color: inherit;
}

.nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control select option {
  background-color: var(--content-bg);
}


.nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .action-buttons {
  justify-content: flex-end;
}

/**
 * Modal Wallet Options Section/Wrapper
 */

.nws-modal-wrapper .modal .wallet-options-wrapper .description {
  margin-top: 0;
  margin-bottom: 20px;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li span {
  font-size: 14px;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
  gap: 10px;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li {
  display: flex;
  justify-content: center;
  padding: 1em;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--wallet-option-border-color);
  border: 1px solid var(--wallet-selector-wallet-option-border-color, var(--wallet-option-border-color));
  transition: background-color 0.2s ease-in-out;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li .wallet-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li .wallet-content .wallet-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li .wallet-content .wallet-img-box img {
  text-align: center;
  width: 100%;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li:hover {
  background-color: var(--selected-wallet-bg-hover);
  background-color: var(--wallet-selector-selected-wallet-bg-hover, var(--selected-wallet-bg-hover));
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li.selected-wallet {
  background-color: var(--selected-wallet-bg);
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li.deprecated-wallet div {
  opacity: 50%;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li .selected-wallet-text {
  text-align: center;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li .selected-wallet-text span {
  font-size: 14px;
  font-weight: 500;
}

.nws-modal-wrapper .modal .wallet-options-wrapper .options-list li:hover {
  box-shadow: 0 2px 10px 0 var(--box-shadow-color);
  box-shadow: 0 2px 10px 0 var(--wallet-selector-box-shadow-color, var(--box-shadow-color));
}

/**
 * Modal Wallet Options Info Section/Wrapper
 */

.nws-modal-wrapper .modal .info {
  margin-top: 20px;
}

.nws-modal-wrapper .modal .info span {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease-out;
}

.nws-modal-wrapper .modal .info .info-description {
  max-height: 0;
  transition: all 300ms ease-out;
  overflow: hidden;
}

.nws-modal-wrapper .modal .info .info-description p {
  font-size: 14px;
  margin-bottom: 0;
}

.nws-modal-wrapper .modal .info .info-description.show-explanation {
  -webkit-animation: inAnimation 350ms ease-in;
          animation: inAnimation 350ms ease-in;
  max-height: 300px;
}

.nws-modal-wrapper .modal .info .info-description.hide-explanation {
  -webkit-animation: outAnimation 200ms ease-out;
          animation: outAnimation 200ms ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/**
 * Modal Wallet Not Installed Section/Wrapper
 */

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
  display: flex;
  align-items: center;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
  color: #5f8afa;
  cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
  margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
  width: 40px;
  height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
  width: 100%;
  height: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}

/**
 * Modal Wallet Connecting Section/Wrapper
 */

.nws-modal-wrapper .modal .connecting-wrapper .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nws-modal-wrapper .spinner {
  margin-top: 20px;
  --size: 160px;
  --border: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nws-modal-wrapper .spinner:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  border: var(--border) solid transparent;
  border-right: var(--border) solid var(--spinner-color);
  border-right: var(--border) solid var(--wallet-selector-spinner-color, var(--spinner-color));
  /*animation: spin 1.4s linear infinite;*/
  -webkit-animation: spin 1.3s ease infinite;
          animation: spin 1.3s ease infinite;
}
.nws-modal-wrapper .spinner .icon {
  width: calc(var(--size) / 1.2);
  height: calc(var(--size) / 1.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 5%) 0 10px 20px 0;
}
.nws-modal-wrapper .spinner img {
  width: 100%;
  height: auto;
  padding: 20px;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nws-modal-wrapper .modal {
    width: 250px;
  }

  .nws-modal-wrapper .modal .derivation-path-wrapper .derivation-path-list input {
    max-width: 140px;
  }

  .nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control {
    flex-direction: column;
  }

  .nws-modal-wrapper .modal .choose-ledger-account-form-wrapper .form-control select {
    text-align: center;
  }
}

.nws-modal-wrapper.dark-theme .modal #near-wallet img,
.nws-modal-wrapper.dark-theme .modal #math-wallet img,
.nws-modal-wrapper.dark-theme .modal #ledger img,
.nws-modal-wrapper.dark-theme .modal .wallet-not-installed-wrapper .math-wallet img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.nws-modal-wrapper.dark-theme .spinner .icon {
  box-shadow: 0 10px 20px 0 rgb(255 255 255 / 5%)
}

@media (prefers-color-scheme: dark) {
  .nws-modal-wrapper .modal #near-wallet img,
  .nws-modal-wrapper .modal #math-wallet img,
  .nws-modal-wrapper .modal #ledger img,
  .nws-modal-wrapper .modal .wallet-not-installed-wrapper .math-wallet img {
    -webkit-filter: invert(1);
            filter: invert(1);
  }

  .nws-modal-wrapper .spinner .icon {
    box-shadow: 0 10px 20px 0 rgb(255 255 255 / 5%)
  }
}

@-webkit-keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

